import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatStepper, StepperOrientation } from '@angular/material/stepper';
import { BehaviorSubject, Observable, Subject, observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../services/data.service';
import { Service } from 'backend/interfaces/service.interface';
import * as moment from 'moment';
import { Practitioner } from 'backend/interfaces/practitioner.interface';
import { Location } from 'backend/interfaces/location.interface';
import { faCalendar, faCalendarAlt, faCheck, faMapMarker, faMapMarkerAlt, faMoneyBill, faNotesMedical, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { AppointmentService } from '../services/appointment.service';
import { Router } from '@angular/router';
import { Member } from 'backend/interfaces/member.interface';

export interface TimeSlot {
  time: string,
  selected: boolean
}
@Component({
  selector: 'app-new-order-component',
  templateUrl: './new-order.component.html',
  styleUrls: ['./../new-appointment/new-appointment.component.scss']
})
export class NewOrderComponentComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  faMoneyBill = faMoneyBill;
  faCalendar = faCalendar;
  faCheck = faCheck;
  faCalendarAlt = faCalendarAlt;
  faMapMarker = faMapMarker;
  faMapMarkerAlt = faMapMarkerAlt;
  faUser = faUserAlt;
  loading = false;
  loadingPractitioners = false;
  services: Service[];
  locations: Location[];
  selectedLocation: string;
  selectedDate: Date;
  startAt = new Date('2023/09/11');
  minDate = new Date();
  maxDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
  busyTimes: Date[];
  timeSlots: TimeSlot[] = [];
  year: any;
  DayAndDate: string;
  practitioners: Member[] = [];
  practitioners$: BehaviorSubject<Practitioner[]> = new BehaviorSubject([]);
  selectedPractitioner = 'Any Available';
  appointmentTypeForm = this._formBuilder.group({
    type: ['', Validators.required],
    duration: [''],
    price: ['']
  });
  appointmentLocationForm = this._formBuilder.group({
    location: ['', Validators.required]
  });
  appointmentDateForm = this._formBuilder.group({
    date: ['', Validators.required]
  });
  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }
  profileForm = this._formBuilder.group({
    Qty: [1, Validators.required],
    Name: ['', Validators.required],
    Mobile: ['', Validators.required],
    Email: ['', Validators.required],
    AdditionalNotes: ['']
  });
  ref;
  stepperOrientation: Observable<StepperOrientation>; 

  constructor(private router: Router, private _formBuilder: UntypedFormBuilder, breakpointObserver: BreakpointObserver, private dataService: DataService, private appointmentService: AppointmentService) {
    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({ matches }) => matches ? 'horizontal' : 'vertical'));
  }

  async ngOnInit(): Promise<void> {
    this.timeSlots = [];
    this.dataService.getServices(100, 0);
    this.dataService.services$.subscribe(async res => {
      this.services = await res?.filter(s => s.price.includes('R'));
    });
  }

  setAppointmentType(title: string, duration: string, price: string) {
    const thePrice = parseFloat(price.replace('R', ''));
    this.appointmentTypeForm.controls['type'].setValue(title);
    this.appointmentTypeForm.controls['duration'].setValue(duration);
    this.appointmentTypeForm.controls['price'].setValue(thePrice);
    this.stepper.next();
  }

  async setLocation(location: string): Promise<void> {
    this.loadingPractitioners = true;
    this.selectedLocation = location;
    this.appointmentLocationForm.controls['location'].setValue(location);
    this.selectedPractitioner = 'Any Available';
    // const practitionersAtLocation = this.practitioners.filter((obj) => {
    //   if(obj.locations){
    //     if(obj.locations.some(location => location.name === this.selectedLocation)){
    //       return obj;
    //     }
    //   }
    // });
    // this.practitioners$.next(practitionersAtLocation);
    this.loadingPractitioners = false;
  }

  setPractitioner(id: string): void {
    console.log(id)
    this.selectedPractitioner = id;
    // this.stepper.next();
  }

  setDate(date: string) {
    this.appointmentDateForm.controls['date'].setValue(date);
    this.stepper.next();
  }

  selectDate(event: any) {
    this.timeSlots = [];
    this.selectedDate = event;
    const dateString = event.toDateString();
    const dateValue = dateString.split(' ');
    this.year = dateValue[3];
    this.DayAndDate = dateValue[0] + ',' + ' ' + dateValue[1] + ' ' + dateValue[2];
    this.getAvailableTimes(this.selectedDate);
  }

  myDateFilter = (d: Date): boolean => {
    const day = d.getDay();
    const now = new Date();
    now.setHours(0,0,0,0);
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 7;
  }

  getAvailableTimes(_event) {
    const date = new Date(_event);
    const duration = parseInt(this.appointmentTypeForm.controls['duration'].value.split(' ')[0]);
    this.dataService.getAvailableTimes(duration, date.getDate(), date.getMonth(), date.getFullYear(), this.selectedLocation, this.selectedPractitioner).then(res => {
      res.forEach(slot => {
        this.timeSlots.push({time: slot, selected: false})
      });
    })
  }

  selectTimeSlot(timeSlot: TimeSlot): void {
    const time = moment(timeSlot.time);
    this.selectedDate = new Date(timeSlot.time);
    this.appointmentDateForm.controls['date'].setValue(timeSlot.time);
    this.stepper.next();
  }

  placeOrder(): void {
    this.loading = true;
    const order = {
      qty: this.profileForm.controls['Qty'].value,
      appointmentType: this.appointmentTypeForm.controls['type'].value,
      customerName: this.profileForm.controls['Name'].value,
      customerMobile: this.profileForm.controls['Mobile'].value,
      customerEmail: this.profileForm.controls['Email'].value,
      additionalNotes: this.profileForm.controls['AdditionalNotes'].value.toString(),
      price: this.appointmentTypeForm.controls['price'].value * this.profileForm.controls['Qty'].value
    }
    this.stepper.next();
    this.appointmentService.createOrder(order).then(res => {
      this.loading = false;
    this.ref = res.reference;
      this.stepper.next();
    }).catch(err => console.log(err));
  }

  getAppointmentsByLocation(): void{
    this.appointmentService.getAppointmentsByLocation('Centurion');
  }

  continueToSignIn(){
    this.router.navigateByUrl('auth');
  }

  goHome(){
    this.router.navigateByUrl('');
  }

}