export enum ParseClasses {
    PATIENTS = 'Students',
    MEMBERS = 'Members',
    STUDENTS = 'Students',
    APPOINTMENTS = 'Appointments',
    SERVICES = 'Services',
    LOCATIONS = 'Locations',
    PRACTITIONERS = 'Practitioners',
    UPLOADS = 'Uploads',
    DAILYREPORT = 'DailyReports',
    MEALPLANS = 'MealPlans',
    MEALS = 'Meals',
    GROUPS = 'Groups',
    MINISTRIES = 'Ministries',
    ACCOUNT = 'Accounts',
    MESSAGES = 'Messages',
    USER = "_User",
    TERMS = 'TermsAndConditions',
    PRIVACY = 'PrivacyPolicy',
    MEMBERCONSENT = 'MemberConsent',
    NEWSLETTER = 'NewsLetter',
    MEDIA = 'Media',
    ORDERS = 'Orders'
}
