<div class="d-flex justify-content-center loader flex-column align-items-center" *ngIf="loading">
  <div>
      <mat-spinner color="accent"></mat-spinner>
  </div>
</div>


<div class="bg-image-1">
    <div class="d-flex justify-content-between align-items-center">
        <img class="img-fluid logo" src="assets/icon.png"/>
        <button mat-raised-button color="accent" (click)="goHome()">Web</button>
        <div class="spacer"></div>
        <h1 class="headline mb-0 m-3 text-white">Nuwe Bestelling</h1>
    </div>
</div>  
<mat-progress-bar [mode]="loading ? 'indeterminate' : 'determinate'" [value]="0">
</mat-progress-bar>
<div class="container-fluid">
<mat-stepper #stepper linear
[orientation]="(stepperOrientation | async)!">
<mat-step [stepControl]="appointmentTypeForm" [label]="appointmentTypeForm.controls['type'].value ? appointmentTypeForm.controls['type'].value : 'Hoe kan ons help'">
  <h1 class="mat-headline mt-4 mb-5">Druk op die item wat jy wil bestel</h1>
            <div class="w-100 d-flex justify-content-center" *ngIf="!services">
              <mat-spinner color="primary"></mat-spinner>
            </div>
            <div class="row d-flex flex-wrap justify-content-center" *ngIf="services">
                <mat-card class="p-3 d-flex flex-column col-lg-6 col-md-6 col-sm-12 m-2 link border-primary" (click)="setAppointmentType(service.title, service.duration, service.price)" *ngFor="let service of services">
                    <mat-card-header class="m-0">
                      <mat-card-title>{{service.title}}</mat-card-title>
                      <mat-card-subtitle class="mb-1">{{service.description}}</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content class="mt-0 ml-3 mr-3 mb-0 flex-1">
                        <p>{{service.note}}</p>
                        <mat-card-subtitle class="mb-1">{{service.price}}</mat-card-subtitle>
                    </mat-card-content>
                </mat-card>
            </div>
</mat-step>

<mat-step [stepControl]="profileForm" label="Jou bestelling">

  <div class="row d-flex justify-content-center">
<div class="col-md-6">

  <form class="user" (ngSubmit)="placeOrder()" [formGroup]="profileForm" class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center flex-wrap">
      <mat-form-field appearance="outline" class="flex-1">
        <mat-label>Hoeveel wil u bestel?</mat-label>
        <input matInput min="1" type="number" placeholder="Kwantiteit" name="Qty" formControlName="Qty">
        <mat-error *ngIf="profileForm.controls.Qty.invalid">Kwantiteit word vereis</mat-error>
      </mat-form-field>
      <p class="mat-display-2 text-primary m-0 p-2">R{{appointmentTypeForm.controls['price'].value * profileForm.controls['Qty'].value}}</p>
    </div>
    <mat-form-field appearance="outline">
        <mat-label>Name en Van</mat-label>
        <input matInput placeholder="Naam en Van" name="name" formControlName="Name">
        <mat-error *ngIf="profileForm.controls.Name.invalid">Naam word vereis</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kontaknommer</mat-label>
        <input matInput placeholder="Nommer" formControlName="Mobile">
        <mat-error *ngIf="profileForm.controls.Mobile.invalid">Nommer word vereis</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Epos Adres</mat-label>
        <input matInput placeholder="Epos" formControlName="Email">
        <mat-error *ngIf="profileForm.controls.Email.invalid">Epos word vereis</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Notas</mat-label>
      <input matInput placeholder="Notas" formControlName="AdditionalNotes">
  </mat-form-field>
  <div class="row d-flex justify-content-center">
    <button mat-button matStepperPrevious>Back</button>
    <button mat-raised-button class="text-white" color="primary" type="submit" [disabled]="profileForm.invalid">Plaas bestelling</button>
  </div>
</form>
</div>

</div>
</mat-step>

<mat-step>
  <ng-template matStepLabel>Betaling</ng-template>
  <div class="row d-flex justify-content-center">
    <div class="col-md-8">
      <mat-card>
        <mat-card-header class="m-0 pt-3">
          <mat-card-title>Dankie</mat-card-title>
          <mat-card-subtitle>jou bestelling is geplaas!</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="p-3">
          <div class="d-flex">
            <div class="p-3">
            <fa-icon [icon]="faCalendar"></fa-icon>
            </div>
            <div class="d-flex align-items-center">{{appointmentTypeForm.controls['type'].value}}</div>
            </div>
            <div class="d-flex">
              <div class="p-3">
              <fa-icon [icon]="faUser"></fa-icon>
              </div>
              <div class="d-flex flex-column">
                {{profileForm.controls['Name'].value}}
                <p style="font-size: 12px; ;">{{profileForm.controls['Mobile'].value}}, {{profileForm.controls['Email'].value}}</p>
              </div>
              </div>
              <div class="d-flex">
                <div class="p-3">
                  <fa-icon [icon]="faMoneyBill"></fa-icon>
                  </div>
                <div class="d-flex align-items-center">R {{appointmentTypeForm.controls['price'].value * profileForm.controls['Qty'].value}}</div>
                </div>
                <div class="d-flex">
                  <div class="p-3">
                  <fa-icon [icon]="faCheck"></fa-icon>
                  </div>
                  <div class="d-flex align-items-center">KKR - {{ref}}</div>
                  </div>


      <div class="row">
        <div class="col-md-12">
          <div class="mt-3">
            <div class="d-flex mat-display-1 m-0">
            <div class="d-flex align-items-center">Betaal opsies</div>
            </div>
            <p>Betaling kan gerieflik vooraf gedoen word deur een van die volgende opsies, of kontant kan op die op die dag betaal word.</p>
            </div>
        </div>
        <div class="col-md-6">
          <div class="p-3 d-flex h-100 border-primary flex-column">
          <h2>EFT</h2>
          <p>
            NG Kempton Kruin<br/>
            Absa-bank tjekrekening<br/>
            Rek.nr 260 580 965<br/>
            Verwysing <b>KKR - {{ref}}</b> <br/>
          </p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="p-3 d-flex h-100 border-primary flex-column">
          <h2>Snapscan</h2>
          <img class="img-fluid" src="../../assets/SnapscanQR.png"/>
          </div>
        </div>
      </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-step>

</mat-stepper>
</div>