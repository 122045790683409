import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '../shared/generic-dialog/generic-dialog.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { MinistryService } from '../admin/services/ministry.service';
import * as Parse from 'parse';
@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  nuusbrieflink;
  advertensiesLink = "https://youtu.be/AyfcFYH6PHQ";
  constructor(private router: Router, private dataService: DataService, private ministryService: MinistryService) { }
  faHome = faHome
  downloading = false;
  async ngOnInit(): Promise<void> {
    this.ministryService.getMinistries();
    await Parse.Cloud.run('getNewsLetter').then(res => {
      if(res)
      this.nuusbrieflink =  res;
    });
    // this.dataService.getMinistries(50, 0);
    // this.appointmentService.getAppointmentsByLocation(null);
  }

  Login(): void{
    this.router.navigateByUrl('auth');
  }

  getFileByIdForDownload(name: string, id: string, mimeType: string){
    this.downloading = true;
    this.dataService.getFileByIdForDownload(id, mimeType).then(res => {
      this.download(name, res);
      this.downloading = false;
    })
  }

  download(name: string, src: any) {
    const linkSource = src;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = name;
    downloadLink.click();
  }

  readonly dialog = inject(MatDialog);
  openDialog() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: 'Roeping',
        body: 'By KRUIN weet ons hoe kompleks die lewe is, en daarom wil ons jou help! Ons nooi jou uit om te kom nes jy is, en Jesus te volg. Ons kan saam ‘n verskil maak deur GOED te DOEN in ons gemeenskap in Kempton Park.'
      },
      width: '500px'
  });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


openContactDialog() {
  const dialogRef = this.dialog.open(ContactComponent, {
});

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}

openAboutDialog() {
  const dialogRef = this.dialog.open(AboutComponent, {
    panelClass: 'dark-dialog'
});

  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}
}
